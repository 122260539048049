
  import {
    network
  } from '../../node_modules/vue-social-sharing/dist/vue-social-sharing'

  export default {
    props: {
      url: {
        type: String,
        default: '000000'
      },
      modalName: {
        type: String,
      },
      modalTitle: {
        type: String,
      },
      modalSize: {
        type: String
      }
    },
    data(){
      return {
        copiedUrl: false
      };
    },
     destroyed() {
      document.body.classList.remove('modalOverflow')
      if(document.getElementById('closeModal'))
      document.getElementById('closeModal').click();
    },
    methods: {
      closeModal() {
        // Emit this information to the parents component
         this.copiedUrl = false;
        this.$emit("child-modal", true);
      },
      copyLink(){
        var Url = this.$refs.mylink;
        Url.select();
        document.execCommand("copy");
        this.copiedUrl = true;
        setTimeout(() => {this.copiedUrl=false;},3000)
      }
    }
  }

